<template>
  <i ref="el" class="scroll-detector" :style="{ top, bottom }" />
</template>

<script setup lang="ts">
const { affix } = useShared()

const props = defineProps({
  id: {
    type: String,
    default: 'top',
  },
  top: {
    type: String,
    default: undefined,
  },
  bottom: {
    type: String,
    default: undefined,
  },
})
const { y } = useWindowScrollPosition()

function handleScroll() {
  if (!process.client) return
  const pos = undefined // document.getElementById('section-form')?.clientHeight * 0.9 || 0
  if (y.value > 50) affix.top = true
  else affix.top = false

  if (pos && y.value > pos) affix.bottom = true
  else affix.bottom = false
}

const el = ref<HTMLElement | null>(null)

const observer = ref<IntersectionObserver | null>(null)

onMounted(() => {
  if ('IntersectionObserver' in window) {
    observer.value = new IntersectionObserver((entries) => {
      if (!observer.value) return
      if (entries[0].boundingClientRect.bottom < 0) {
        affix[props.id] = true
      } else {
        affix[props.id] = false
      }
    })
    if (!el.value) return
    observer.value.observe(el.value)
  } else {
    window.addEventListener('scroll', throttle(handleScroll, 50), {
      passive: true,
    })
    handleScroll()
  }
})

onUnmounted(() => {
  if (!('IntersectionObserver' in window))
    window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
.scroll-detector {
  position: absolute;
  left: 50vw;

  width: 1px;
  height: 1px;
}
</style>
